<template>
    <div class="row">
        <div class="col">

            <div class="card">
                <div class="card-header">
                    <h5>Locate Claim</h5>
                </div>
                <div class="card-body">

                    <p><strong>Please note:</strong> you must enter the full reference number for the search to work.</p>

                    <div class="form-group m-b-sm" style="max-width: 450px;">
                        <label for="claim_number">Claim Reference <span class="text-danger">*</span></label>
                        <input type="text" v-model="reference" class="form-control" id="claim_number" placeholder="Claim Reference">
                    </div>

                    <button class="btn btn-sm btn-primary float-end" @click="getClaims" :disabled="locating">
                        <span v-if="locating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Locate Claim
                    </button>

                </div>
            </div>

        </div>
    </div>

    <div v-if="claim && !locating" class="row">
        <div class="col">

            <table class="table">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Reference</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{claim.client_name}}</td>
                        <td>{{claim.reference}}</td>
                        <td>{{claim.type}}</td>
                        <td>{{claim.status}}</td>
                        <td><a :href="`/${claim.id}/${claim.client_id}`" class="btn btn-sm btn-primary">View Claim</a></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';

export default {
    name: "Claims",
    data(){
        return {
            reference: "",
            claim: null,
            locating: false,
            typeDictionary: {
                'driveoff': 'Drive Off',
                'nmop': 'No Means of Payment',
                'escalated': 'Drive Off (No Means of Payment)',
                'cnaf': 'Customer Not At Fault',
                'cnafe': 'Drive Off (Customer Not at Fault)',
                'parking': 'Parking',
                'evparking': 'Electric Vehicle Parking',
            }
        }
    },
    mounted(){

    },
    methods: {
        getClaims(){
            this.locating = true;
            axios.get(`https://api.varsanpr.com/api/dcbl?reference=${this.reference}`, { headers: authHeader() })
            .then(response => {
                this.claim = response.data;
                this.claim.type = this.typeDictionary[this.claim.type];
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error.message, error);
            })
            .finally(() => {
                this.locating = false;
            })
        }
    }
}
</script>